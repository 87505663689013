import { useCallback, useEffect, useState, useRef } from "react";
import '../../css/position-job-card.scss';
import attachmentImg from '../../assets/multimedia.svg'
import attachmentWImg from '../../assets/multimedia-w.svg'
import backarrowImg from '../../assets/back-arrow.svg'
import { Button, IconButton, TextField } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import { isValid, isValidEmail, isValidFullName, isValidLnAndXing, isNumberOnly } from "../../common/contstants";
import { useIntl } from 'react-intl';
function ApplyJobCard(props) {
  const { formatMessage } = useIntl();
  const firstNameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const linkedinRef = useRef(null);
  const xingRef = useRef(null);
  const messageRef = useRef(null);
  const submitRef = useRef(null);

  const [activeIdx, setActiveIdx] = useState(0)
  const [isSubmitted, setSubmitted] = useState(false)
  const [spinnerStatus, setSpinnerStatus] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('');
  const [linkedin, setLinkedIn] = useState('');
  const [xing, setXING] = useState('');
  const [message, setMessage] = useState('');
  const [file, setFile] = useState();
  const [attachmentError, setAttachmentError] = useState('');
  const { job, handleApplyJob, applyRes } = props;

  const cardLength = 7;
  const fields = {
    firstName: 'firstName',
    email: 'email',
    phone: 'phone',
    linkedin: 'linkedin',
    xing: 'xing',
    message: 'message',
    file: 'file'
  }
  const timeoutValue = 500;
  const updateCurrentProfile = (idx) => {
    setActiveIdx(idx);
    if (idx === 1) {
      setInputFocus(emailRef);
    } else if (idx === 2) {
      setInputFocus(phoneRef);
    } else if (idx === 3) {
      setInputFocus(linkedinRef);
    } else if (idx === 4) {
      setInputFocus(xingRef);
    } else if (idx === 5) {
      setInputFocus(messageRef);
    }
  }

  const setInputFocus = (ref) => {
    setTimeout(() => {
      ref.current.focus();
      ref.current.click();
    }, timeoutValue);
  }

  const BackToPrev = () => {
    return <img src={backarrowImg} onClick={handleBack} className="job-card-back-arrow" alt="back arrow" />
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      const id = event?.target.id;
      handleClick(id);
    }
  };

  const handleClick = useCallback((type) => {
    setSubmitted(false)
    if (type === fields.firstName) {
      if (!firstName || (firstName && !isValidFullName(firstName))) {
        setSubmitted(true)
      } else {
        setActiveIdx(activeIdx + 1);
      }
    } else if (type === fields.email) {
      if (email) {
        if (!isValidEmail(email)) {
          setSubmitted(true)
          return;
        }
        setActiveIdx(activeIdx + 1);
      } else {
        setSubmitted(true)
      }
    } else if (type === fields.file) {
      const { vacancyId } = job;
      setSpinnerStatus(true);
      if (file && !attachmentError) {
        var formdata = new FormData();
        formdata.append("email", email);
        formdata.append("name", firstName);
        formdata.append('vacancyId', vacancyId)
        if (phone)
          formdata.append("phone", phone);
        if (message)
          formdata.append("message", message);
        if (linkedin)
          formdata.append("linkedin", linkedin);
        if (xing)
          formdata.append("xing", xing);
        if (file)
          formdata.append("file", file, file.name);

        handleApplyJob(formdata);

        // reset form fields after submission 
        const input = document.querySelectorAll('.apply-job-input input');
        for (var i = 0; i < input.length; i++) {
          input[i].value = "";
        }
        for (const key in fields) {
          if (fields.hasOwnProperty(key)) {
            setData('', fields[key])
          }
        }
        // Reset input type file
        document.getElementById('apply-job-cv').value = "";
        setFile('')

      }
    } else if (type === fields.xing) {
      if (xing && !isValidLnAndXing(xing)) {
        setSubmitted(true)
        return;
      }
      setActiveIdx(activeIdx + 1);
    } else if (type === fields.linkedin) {
      if (linkedin && !isValidLnAndXing(linkedin)) {
        setSubmitted(true)
        return;
      }
      setActiveIdx(activeIdx + 1);
    } else if (type === fields.message) {
      if (message && !isValid(message)) {
        setSubmitted(true)
        return;
      }
      setActiveIdx(activeIdx + 1);
    }
    else {
      if (phone && !isNumberOnly(phone)) {
        setSubmitted(true)
        return;
      }
      setActiveIdx(activeIdx + 1);
    }

  }, [activeIdx, firstName, email, phone, linkedin, xing, message, file]);

  const handleBack = useCallback(() => {
    setActiveIdx(activeIdx - 1);
    setSubmitted(false)
  }, [activeIdx]);

  const setData = (val, type) => {
    if (type === fields.firstName) {
      setFirstName(val)
    } else if (type === fields.email) {
      setEmail(val);
    } else if (type === fields.phone) {
      setPhone(val);
    } else if (type === fields.linkedin) {
      setLinkedIn(val);
    } else if (type === fields.xing) {
      setXING(val);
    } else if (type === fields.message) {
      setMessage(val)
    }
  }

  const handleFileChange = (e) => {
    const allowedFileTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf"
    ]
    if (e.target.files) {
      const file = Math.round((e.target.files[0]?.size / 1024));

      if (allowedFileTypes.includes(e.target.files[0]?.type) && file <= 10240) {
        setFile(e.target.files[0]);
        setAttachmentError('');
        submitRef.current.focus();
      } else {
        setAttachmentError(formatMessage({ id: 'attachment_validation' }));
        setFile('')
      }
    }
  };

  useEffect(() => {
    if (activeIdx > 0 && applyRes?.message && (!applyRes?.error || applyRes?.error === "")) {
      setSpinnerStatus(false);
      setTimeout(() => {
        if (setActiveIdx) {
          setActiveIdx(0);
        }
      }, 3000);
    }
  }, [applyRes?.message]);

  return (
    <div>
      <div className='client-banner'>

        <Carousel showArrows={false} interval={3000} showStatus={false} showIndicators={false} infiniteLoop={false}
          showThumbs={false}
          swipeable={false}
          selectedItem={activeIdx}
          onChange={(e) => updateCurrentProfile(e)}
        >
          <div className="apply-job-card">
            <div>
              <div className="lbl-apply-now">{formatMessage({ id: "job_submit_card_1_title" })}</div>
              <TextField
                required={!firstName}
                error={isSubmitted && (!firstName || (firstName && !isValidFullName(firstName)))}
                helperText={isSubmitted && (!firstName ? formatMessage({ id: 'name_validation' }) : (!isValidFullName(firstName) ? formatMessage({ id: 'name_message' }) : ''))}
                id={fields.firstName}
                onChange={(e) => setData(e.target.value, fields.firstName)}
                onKeyDown={handleKeyPress}
                inputRef={firstNameRef}
                className='ipt-field-common apply-job-input'
                label={formatMessage({ id: "job_submit_field_1" })}
                variant="standard" />
            </div>
            <div className="apply-job-card-action">
              <Button onClick={(e) => handleClick(fields.firstName)} className="job-card-btn-next">{formatMessage({ id: "job_submit_card_button_next" })}</Button>
              <div className="job-card-item-index">{(activeIdx + 1).toString()}/{cardLength}</div>
            </div>
          </div>
          <div className="apply-job-card">
            <div>
              <div className="lbl-apply-now">
                {formatMessage({ id: "job_submit_card_1_title" })}
                <BackToPrev />
              </div>
              <TextField
                required={!email}
                error={isSubmitted && (!email || (email && !isValidEmail(email)))}
                helperText={isSubmitted && (!email ? formatMessage({ id: 'email_validation' }) : (!isValidEmail(email) ? formatMessage({ id: 'email_message' }) : ''))}
                id={fields.email}
                onChange={(e) => setData(e.target.value, fields.email)}
                onKeyDown={handleKeyPress}
                inputRef={emailRef}
                className='ipt-field-common apply-job-input'
                label={formatMessage({ id: "job_submit_field_2" })} variant="standard" />
            </div>
            <div className="apply-job-card-action">
              <Button onClick={(e) => handleClick(fields.email)} className="job-card-btn-next">{formatMessage({ id: "job_submit_card_button_next" })}</Button>
              <span className="job-card-item-index">{(activeIdx + 1).toString()}/{cardLength}</span>
            </div>
          </div>
          <div className="apply-job-card">
            <div>
              <div className="lbl-apply-now">{formatMessage({ id: "job_submit_card_1_title" })}
                <BackToPrev />
              </div>
              <TextField
                id={fields.phone}
                error={!!isSubmitted && phone && !isNumberOnly(phone)}
                helperText={isSubmitted && phone && (!isNumberOnly(phone) ? formatMessage({ id: 'phone_validation' }) : '')}
                onChange={(e) => setData(e.target.value, fields.phone)}
                onKeyDown={handleKeyPress}
                inputRef={phoneRef}
                className='ipt-field-common apply-job-input' label={formatMessage({ id: "job_submit_field_3" })} variant="standard" />
            </div>
            <div className="apply-job-card-action">
              <Button onClick={(e) => handleClick(fields.phone)} className={`job-card-btn-next`}>{phone ? formatMessage({ id: "job_submit_card_button_next" }) : formatMessage({ id: "job_submit_card_button_skip" })}</Button>
              <span className="job-card-item-index">{(activeIdx + 1).toString()}/{cardLength}</span>
            </div>
          </div>
          <div className="apply-job-card">
            <div>
              <div className="lbl-apply-now">{formatMessage({ id: "job_submit_card_1_title" })}
                <BackToPrev />
              </div>
              <TextField
                id={fields.linkedin}
                error={isSubmitted && linkedin && !isValidLnAndXing(linkedin)}
                helperText={isSubmitted && linkedin && (!isValidLnAndXing(linkedin) ? formatMessage({ id: 'name_message' }) : '')}
                onChange={(e) => setData(e.target.value, fields.linkedin)}
                onKeyDown={handleKeyPress}
                inputRef={linkedinRef}
                className='ipt-field-common apply-job-input'
                label={formatMessage({ id: "job_submit_field_4" })}
                variant="standard" />
            </div>
            <div className="apply-job-card-action">
              <Button onClick={(e) => handleClick(fields.linkedin)} className={`job-card-btn-next`}>{linkedin ? formatMessage({ id: "job_submit_card_button_next" }) : formatMessage({ id: "job_submit_card_button_skip" })}</Button>
              <span className="job-card-item-index">{(activeIdx + 1).toString()}/{cardLength}</span>
            </div>
          </div>

          <div className="apply-job-card">
            <div>
              <div className="lbl-apply-now">{formatMessage({ id: "job_submit_card_1_title" })}
                <BackToPrev />
              </div>
              <TextField
                id={fields.xing}
                error={isSubmitted && xing && !isValidLnAndXing(xing)}
                helperText={isSubmitted && xing && (!isValidLnAndXing(xing) ? formatMessage({ id: 'name_message' }) : '')}
                onChange={(e) => setData(e.target.value, fields.xing)}
                onKeyDown={handleKeyPress}
                inputRef={xingRef}
                className='ipt-field-common apply-job-input'
                label={formatMessage({ id: "job_submit_field_5" })}
                variant="standard" />
            </div>
            <div className="apply-job-card-action">
              <Button onClick={(e) => handleClick(fields.xing)} className={`job-card-btn-next`}>{xing ? formatMessage({ id: "job_submit_card_button_next" }) : formatMessage({ id: "job_submit_card_button_skip" })}</Button>
              <span className="job-card-item-index">{(activeIdx + 1).toString()}/{cardLength}</span>
            </div>
          </div>

          <div className="apply-job-card">
            <div>
              <div className="lbl-apply-now">{formatMessage({ id: "job_submit_card_1_title" })}
                <BackToPrev />
              </div>
              <TextField
                id={fields.message}
                error={isSubmitted && message && !isValid(message)}
                helperText={isSubmitted && message && (!isValid(message) ? formatMessage({ id: 'name_message' }) : '')}
                onChange={(e) => setData(e.target.value, fields.message)}
                onKeyDown={handleKeyPress}
                inputRef={messageRef}
                className='ipt-field-common apply-job-input'
                label={formatMessage({ id: "job_submit_field_6" })}
                variant="standard" />
            </div>
            <div className="apply-job-card-action">
              <Button onClick={(e) => handleClick(fields.message)} className={`job-card-btn-next`}>{message ? formatMessage({ id: "job_submit_card_button_next" }) : formatMessage({ id: "job_submit_card_button_skip" })}</Button>
              <span className="job-card-item-index">{(activeIdx + 1).toString()}/{cardLength}</span>
            </div>
          </div>

          <div className={`apply-job-card ${(spinnerStatus) ? 'is-sending' : ''}`}>
            {
              applyRes && (!applyRes.message) ?
                <div className="apply-job-file-wrapper">
                  <div>
                    <div className="lbl-apply-now">{formatMessage({ id: "job_submit_card_1_title" })}
                      <BackToPrev />
                    </div>
                    <div className="file-container">
                      <IconButton
                        color="primary"
                        id="file-icon-bg"
                        className={`${(file && file.name) ? 'file-icon-o-bg' : 'file-icon-bg'}`}
                        aria-label="upload picture"
                        component="label">
                        <input
                          hidden
                          accept=".doc,.pdf"
                          type="file"
                          className="ipt-field-common apply-job-input"
                          id="apply-job-cv"
                          onChange={handleFileChange}
                        />
                        <img className='attachment-icon' src={(file && file.name) ? attachmentWImg : attachmentImg} alt="attachment" />
                      </IconButton>
                      <span className='lbl-attachment'>{file ? `${file.name} ` + formatMessage({ id: 'job_submit_field_7_upload' }) : formatMessage({ id: "job_submit_field_7" })}</span>
                      {attachmentError && <div className='contact-error-cont error-msg'>{attachmentError}</div>}
                    </div>
                  </div>
                  <div className="apply-job-card-action">
                    <Button
                      onClick={(e) => handleClick(fields.file)}
                      ref={submitRef}
                      className={`job-card-btn-file ${!file && 'job-card-btn-disabled'}`}>{formatMessage({ id: "job_submit_button" })}</Button>
                    <span className="job-card-item-index">{(activeIdx + 1).toString()}/{cardLength}</span>
                  </div>
                  {applyRes && applyRes.error && <div className="message-style error-msg">
                    {applyRes.message || applyRes.error}
                  </div>
                  }
                  <div className={`sk-loader ${(spinnerStatus) ? 'show-spinner' : ''}`}></div>
                </div>
                :
                <div className="apply-message">
                  {formatMessage({ id: applyRes.message }) || applyRes.error}
                </div>

            }
          </div>

        </Carousel>
      </div>
    </div>
  )
}

export default ApplyJobCard; 